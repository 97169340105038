<template>
    <div id="barChart"></div>
</template>

<script>
import axios from '../../middleware/axios'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
export default {
    name: "frontEndBarChart",
    data() {
        return {
            data: null
        }
    },
    mounted() {
        this.chart = am4core.create("barChart", am4charts.XYChart);
        //this.chart.scrollbarX = new am4core.Scrollbar();

        this.chart.data = []

        // Create axes
        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.minHeight = 110;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;

        // Create series
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.tooltipText = "kWh: [bold]{valueY}[/]";
        series.columns.template.strokeWidth = 0;

        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.fillOpacity = 0.8;

        // on hover, make corner radiuses bigger
        let hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;


        // Cursor
        this.chart.cursor = new am4charts.XYCursor();

        if(this.data === null){

            const api_url = 'https://solareye.ntx.bg:9091/frontPage/last7days';

            axios.get( api_url )
            .then(res => {
            console.log(res.data)
            if(res.data) {
                this.chart.data = res.data;
            }
            })
            .catch(err => {
            console.log(err);        
            })
        } else {
            this.chart.data = this.data;
        }
    },
    methods: {
        convertChartData(data) {
            let t = [];
            for(const {date, value} of data) {
                let t1 = {
                    date: new Date(date),
                    value: value
                }
                t.push(t1);
            }
            return t;
        },
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}
</script>

<style scoped>
#barChart {
    height: 300px;
    width: 100%;
}
</style>