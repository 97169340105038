import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Default from './components/layouts/Default.vue'
import NoSidebar from './components/layouts/NoSidebar.vue'



Vue.component('default-layout', Default);
Vue.component('no-sidebar-layout', NoSidebar);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
