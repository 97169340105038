<template>
    <div>
        <Header />
        <div class="container">
            
            <slot />
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '../pageComponents/Header.vue'
import Footer from '../pageComponents/Footer.vue'
export default {
    name: "Default",
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
.container {
    min-height: calc(100vh - 40px);
    background: #ffffff;
}
</style>