import axios from 'axios'
import store from '../store/'
import router from '../router/'

axios.interceptors.request.use(function(config) {
  const token = store.state.token;

  console.log(token);
  
  if(token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function(err) {
    return Promise.reject(err);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    console.log("User is not authorized, we need to redirect to login page");
    store.commit('loggedOut');
    router.push('/');
    return Promise.reject(error);
  }
  return error;
});

export default axios;