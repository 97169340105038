import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "no-sidebar" },
    component: require("@/components/pages/Login.vue").default
  },
  {
    path: "/devices",
    name: "devices",
    component: () => import("@/components/devices/List.vue")
  },
  {
    path: "/devices/details/:id",
    name: "details",
    component: () => import("@/components/devices/Details.vue"),
    props: true
  },
  {
    path: "/devices/charts/:id",
    name: "charts",
    component: () => import("@/components/devices/Charts.vue"),
    props: true
  },
  {
    path: "/devices/reports/:id",
    name: "reports",
    component: () => import("@/components/devices/Reports.vue"),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router