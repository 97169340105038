<template>
<div class="row h-100">
    <div class="col-sm-12 my-auto mt-2">
        <slot />
    </div>
    </div>
</template>

<script>
export default {
    name: "NoSidebar"
}
</script>

<style scoped>
</style>