<template>
    <div class="container">
        <b-card title="Вход" v-if="show" class="my-auto mx-auto text-center" style="max-width: 25rem; display:block">
            <b-form @submit="onSubmit" @reset="onReset" >
                <b-input-group class="mb-2" size="sm">
                    <b-input-group-prepend is-text>
                        <b-icon icon="person-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="text" v-model="form.email" placeholder="Имейл"></b-form-input>
                </b-input-group>
                <b-input-group class="mb-2" size="sm">
                    <b-input-group-prepend is-text>
                        <b-icon icon="key-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="password" v-model="form.password" placeholder="Парола"></b-form-input>
                </b-input-group>
                <b-alert variant="warning" show fade v-if="isLoading" class="alert-sm">Моля изчакайте...</b-alert>
                <b-alert variant="danger" show fade v-if="isError">Грешка: {{ isError }}</b-alert>
                <b-button variant="primary" type="submit" :disabled="isLoading == true" class="btn-sm">Вход</b-button>
                <b-button variant="danger" type="reset" class="btn-sm ml-2">Изчистване</b-button>
            </b-form>
        </b-card>
        <b-row class="text-center">
            <b-col class="mt-2 col-md-6 col-sm-12 col-lg-6 col-xs-12 col-xl-6">
                <b-card title="Производство в реално време [kW]" v-if="show">
                    <frontEndLineChart />
                </b-card>
            </b-col>
            <b-col class="mt-2 col-md-6 col-sm-12 col-lg-6 col-xs-12 col-xl-6">
                <b-card title="Производство за последните 7 дни [kWh]" v-if="show">
                    <frontEndBarChart key="production" />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-2 col-md-12 col-sm-12 col-lg-12 col-xs-12">
                <b-card title="Цена на електрическата енергия" v-if="show">
                    <frontEndPriceChart key="price"/>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-2 col-md-12 col-sm-12 col-lg-12 col-xs-12">
                <b-card title="Производство на ЕЕС (ФЕЦ)" v-if="show">
                    <frontEndESOChart key="eso"/>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import frontEndLineChart from '../charts/FrontEndLineChart.vue'
import frontEndBarChart from '../charts/FrontEndBarChart.vue'
import frontEndPriceChart from '../charts/FrontEndPriceChart.vue'
import frontEndESOChart from '../charts/FrontEndESOChart.vue'

export default {
  name: 'Login',
  components: {
      frontEndLineChart,
      frontEndBarChart,
      frontEndPriceChart,
      frontEndESOChart
  },
  data() {
      return {
          show: true,
          form: {
            email: '',
            password: ''
          },
          isLoading: false,
          isError: '',
          isAuthenticated: true
      }
  },
  mounted: function() {
      if(this.$store.state.isAuthenticated) {
          this.$router.push('/devices');
      }
  },
  methods: {
      onSubmit(event) {
        event.preventDefault()
        const { email, password } = this.form
        this.isLoading = true;
        this.isError = '';

        axios({ url: 'https://solareye.ntx.bg:9091/user/login', data: {email: email, password: password }, method: 'POST'})
        .then(res => {
            console.log(res);
            const token = res.data.token;
            const expiry = 86400 + Date.now();
            this.isLoading = false;
            this.isError = '';
            this.$store.commit('loggedIn', { token: token, expiry: expiry, fName: res.data.fName, lName: res.data.lName, email: res.data.email, deviceCount: res.data.deviceCount, role: res.data.role });
            this.$router.push('/devices')
        })
        .catch(err => {
            localStorage.removeItem('user-token')
            this.isLoading = false;
            if(err.response) {
                this.isError = err.response.data.error;
            }
            console.log(err)
        })
      },
      onReset(event) {
          event.preventDefault()
          this.form.email = ''
          this.form.password = ''
      }
  }
}
</script>

<style scoped>

</style>