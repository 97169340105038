<template>
    <div class="lineChart">
    </div>
</template>

<script>
import axios from '../../middleware/axios'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    name: 'frontEndLineChart',
    data() {
        return {
            data: null
        }
    },
    mounted() {
        this.lineChart = am4core.create("lineChart", am4charts.XYChart);
        this.lineChart.paddingRight = 20;
        this.lineChart.data = [];

        let sdateAxis = this.lineChart.xAxes.push(new am4charts.DateAxis());
        sdateAxis.tooltipDateFormat = "HH:mm, d MMMM";
        sdateAxis.renderer.labels.template.fontSize = 8
        let svalueAxis = this.lineChart.yAxes.push(new am4charts.ValueAxis());
        svalueAxis.renderer.labels.template.fontSize = 8
        svalueAxis.tooltip.disabled = true;
        let sseries = this.lineChart.series.push(new am4charts.LineSeries());
        sseries.dataFields.dateX = "date";
        sseries.dataFields.valueY = "value";
        sseries.tooltipText = "kW: [bold]{valueY}[/]";
        sseries.fillOpacity = 0.3;
        this.lineChart.cursor = new am4charts.XYCursor();
        this.lineChart.cursor.lineY.opacity = 0;
        if(this.data) {
            this.lineChart.cursor = new am4charts.XYCursor();
            this.lineChart.cursor.lineY.opacity = 0;
            sseries.tooltipText = "Power: [bold]{valueY}[/]";
        }
        
        sdateAxis.start = 0.0;
        sdateAxis.keepSelection = true;

        if(this.data === null){

            const api_url = 'https://solareye.ntx.bg:9091/frontPage/timeline';

            axios.get( api_url )
            .then(res => {
            console.log(res.data)
            if(res.data) {
                //this.result = res.data
                this.lineChart.data = this.convertChartData(res.data);
            }
            })
            .catch(err => {
            console.log(err);        
            })
        } else {
            this.lineChart.data = this.convertChartData(this.data);
        }
    },
    methods: {
        convertChartData(data) {
            let t = [];
            for(const {date, value} of data) {
                let t1 = {
                    date: new Date(date),
                    value: value
                }
                t.push(t1);
            }
            return t;
        },
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

</script>

<style scoped>
.lineChart {
    height: 300px;
    width: 100%;
}
</style>