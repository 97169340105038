import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        isAuthenticated: false,
        token: '',
        expiry: 0,
        username: '',
        deviceCount: 0
    },
    mutations: {
        loggedIn (state, p) {
            state.isAuthenticated = true
            state.token = p.token
            state.expiry = p.expiry
            state.fName = p.fName
            state.lName = p.lName
            state.email = p.email
            state.role = p.role
            state.deviceCount = p.deviceCount
        },
        loggedOut (state) {
            state.isAuthenticated = false;
            state.token = ''
            state.expiry = 0
            state.fName = ''
            state.lName = ''
            state.email = ''
            state.role = ''
            state.deviceCount = 0
        }
    }
})

export default store